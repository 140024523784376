/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import theme from "@mytutor/mytutor-design-system/styles/theme";

type ThemeRegistryProps = {
  children: React.ReactNode;
};

export function ThemeRegistry(props: Readonly<ThemeRegistryProps>) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
